<template>
  <div class="h-100">
    <query-form
      class="content_block"
      label-width="120px"
      @search="handleSearch"
      :span="8"
      @reset="handleReset"
    >
      <el-form-item label="备件类型编码：">
        <el-input
          v-model="query.code"
          clearable
          placeholder="请输入备件类型编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="备件类型名称：">
        <el-input
          v-model="query.name"
          clearable
          placeholder="请输入备件类型名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="上级备件类型：">
        <el-row type="flex">
          <SpareTypesSelectors :name="sparePartTypeName" :isQuery="true" style="flex: 1" @change="(v) => handleTypeChange(v, 'form')" />
        </el-row>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker
          v-model="times"
          type="daterange"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="tableList"
      class="content_block"
      id="sparepartsTable"
      show-right-toolbar
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="createTimeSortInfo"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
    <el-dialog
      width="720px"
      :title="`${type === 'add' ? '新增' : '编辑'}备件类型`"
      :visible.sync="dialogVisible"
    >
      <el-form
        label-width="120px"
        ref="spareTypeForm"
        :rules="spareTypeFormRules"
        :model="spareTypeForm"
      >
        <el-row>
          <el-col v-if="type === 'edit'" :span="12">
            <el-form-item label="备件类型编码：">
              <el-input
                v-model="spareTypeCode"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备件类型名称：" prop="name">
              <el-input
                v-model="spareTypeForm.name"
                clearable
                placeholder="请输入备件类型名称"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上级备件类型：" prop="parentId">
              <SpareTypesSelectors :isParent="type === 'edit'" :id="spareTypeForm.id || ''"  :name="spareTypeForm.parentName" @change="(v) => handleTypeChange(v, 'dialog')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSaveDevice">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { getTypeList, addSparePartType, getSpareDeviceDetail, updateSparePartType } from '@/api/spareParts';
import SpareTypesSelectors from '@/components/spareTypeSlelector';

export default {
  name: 'SPAREPART_TYPE',
  mixins: [TablePaginationMixin],
  components: {
    SpareTypesSelectors,
  },
  data () {
    return {
      tableList: [],
      columnList: [
        {
          tooltip: true,
          label: '备件类型名称',
          minWidth: '140px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '备件类型编码',
          minWidth: '140px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '上级设备类型',
          minWidth: '140px',
          prop: 'parentName',
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '140px',
          prop: 'createTime',
        },
        {
          tooltip: true,
          label: '最后操作时间',
          minWidth: '140px',
          prop: 'lastUpdateTime',
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            actions.push(
              <span
                v-auth={'sparePart:type:update'}
                class="cursor-pointer text-primary ml-1 "
                onClick={this.handleActionClick.bind(this, row, 'edit')}
              >
                编辑
              </span>,
            );
            return <div>{...actions}</div>;
          },
        },
      ],
      query: {
        name: '',
        code: '',
        parentId: '',
        startTime: '',
        endTime: '',
      },
      times: [],
      spareTypeForm: {
        name: '',
        parentId: '',
        parentName: '',
      },
      dialogVisible: false,
      spareTypeFormRules: {
        name: [
          { required: true, message: '请输入备件类型名称', trigger: 'blur' },
        ],
      },
      type: 'add',
      spareTypeCode: '',
      sparePartTypeName: '全部',
    };
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        name: '',
        code: '',
        startTime: '',
        endTime: '',
        parentId: '',
      };

      this.times = [];
      this.sparePartTypeName = '全部';
      this.handleSearch();
    },
    getTableList () {
      this.query.startTime = this.times[0] || '';
      this.query.endTime = this.times[1] || '';

      getTypeList({
        ...this.query,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        ...this.createTimeSortInfo,
      }).then((res) => {
        this.tableList = res.body?.list || [];
        this.pagination.total = res.body?.total || 0;
      });
    },

    getSpareDeviceDetail (id) {
      getSpareDeviceDetail({id}).then((res) => {
        if (res.body) {
          this.spareTypeForm = res.body;

          this.fileLists = res.body.picture.map((v) => ({
            url: v.url,
            annexName: v.attachName,
          }));

          this.devices = this.spareTypeForm.device.map((v) => ({
            equipmentName: v.name,
            id: v.id,
          }));
        }
      });
    },
    handleActionClick (item, action) {
      if (action === 'edit') {
        this.dialogVisible = true;
        this.type = 'edit';

        this.$nextTick(() => {
          this.$refs.spareTypeForm.resetFields();

          this.spareTypeCode = item.code;
          this.spareTypeForm.id = item.id;
          this.spareTypeForm.name = item.name;
          this.spareTypeForm.parentId = item.parentId;
          this.spareTypeForm.parentName = item.parentName;
        });
      }
    },
    handleTypeChange (v, type) {
      if (type === 'form') {
        this.query.parentId = v.id;
        this.sparePartTypeName = v.name;
        this.handleSearch();
      } else if (type === 'dialog') {
        this.spareTypeForm.parentId = v.id;
        this.spareTypeForm.parentName = v.name;
      }
    },
    handleSaveDevice () {
      this.$refs.spareTypeForm.validate((valid) => {
        if (valid) {
          const api = this.type === 'add' ? addSparePartType : updateSparePartType;
          const msg = this.type === 'add' ? '新增' : '编辑';

          let params = {
            ...this.spareTypeForm,
          };

          api(params).then(() => {
            this.dialogVisible = false;
            this.$message({
              message: `${msg}成功`,
              type: 'success',
            });
            this.handleReset();
          });
        }
      });
    },
  },
  computed: {
    tableButtons () {
      let actions = [
        {
          name: '新增',
          status: 'primary',
          size: 'small',
          icon: 'el-icon-plus',
          permission: 'sparePart:type:add',
          click: () => {
            this.dialogVisible = true;
            this.type = 'add';
            this.devices = [];
            this.spareTypeForm.name = '';
            this.spareTypeForm.parentId = '';
            this.spareTypeForm.parentName = '';

            this.$nextTick(() => {
              this.$refs.spareTypeForm.resetFields();
            });
          },
        },
      ];
      return actions;
    },
  },
};
</script>
<style scoped lang="scss">
.el-date-editor--daterange.el-input__inner {
  width: 100%;
}
</style>
